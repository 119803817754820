/**
 *
 * App.js
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 *
 */

import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import { hot } from 'react-hot-loader/root';
import LoginPage from 'containers/LoginPage/Loadable';
import NotFoundPage from 'containers/NotFoundPage/Loadable';

import DashboardLayout from './dashboardLayout';
import TrackPage from '../TrackPage/Loadable';

function App() {
  const isLogin = localStorage.getItem('secret');

  return (
    <div>
      {isLogin ? (
        <DashboardLayout />
      ) : (
        <Switch>
          <Route exact path="/" component={LoginPage} />
          <Route exact path="/track" component={TrackPage} />
          <Route path="" component={NotFoundPage} />
          <Redirect to="/" />
        </Switch>
      )}
    </div>
  );
}
export default hot(App);

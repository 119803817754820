import React, { useEffect, useState } from 'react';

import { Avatar, Col, Layout, Menu, Row } from 'antd';
import DashboardIcon from '../../components/common/assets/images/dashboard_icon_select.svg';
import FarmIcon from '../../components/common/assets/images/notebook.svg';
import ProductIcon from '../../components/common/assets/images/seeding.svg';
import MapIcon from '../../components/common/assets/images/place.svg';
import SettingsIcon from '../../components/common/assets/images/setting_icon_small.svg';
import LogoutIcon from '../../components/common/assets/images/logout icon small.svg';
import { NavLink, Route, Switch } from 'react-router-dom';

import Logo from '../../components/common/assets/images/greens_logo.png';

import HomePage from 'containers/HomePage/Loadable';
import MyFarmPage from '../MyFarmPage/Loadable';
import MyProductPage from '../MyProductPage/Loadable';
import GreenspodMapPage from '../GreenspodMapPage/Loadable';

const { Header, Sider, Content, Footer } = Layout;

export default function DashboardLayout() {
  const [currentPage, setCurrentPage] = useState(
    `/${window.location.pathname.split('/')[1]}`,
  );
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [showSearch, setShowSearch] = useState();

  const username = localStorage.getItem('username');

  useEffect(
    () => getCurrentPage({ key: `/${window.location.pathname.split('/')[1]}` }),
    [currentPage],
  );

  const getCurrentPage = (value) => {
    switch (value.key) {
      case '/':
        setShowSearch(false);
        setCurrentPage('dashboard');
        break;
      case '/my-farm':
        setShowSearch(true);
        setCurrentPage('my-farm');
        break;
      case '/my-product':
        setShowSearch(true);
        setCurrentPage('my-product');
        break;
      case '/greenspod-map':
        setShowSearch(false);
        setCurrentPage('greenspod-map');
        break;

      default:
        setCurrentPage('-');
        break;
    }
  };

  return (
    <Layout className="dashboard">
      <Sider
        className={`dashboard__sidebar ${
          !isCollapsed && 'dashboard__sidebar-default'
        }`}
        breakpoint={'lg'}
        collapsed={isCollapsed}
        onBreakpoint={(broken) => {
          console.log(broken);
        }}
        onCollapse={(collapsed) => {
          setIsCollapsed(collapsed);
        }}
      >
        <div className="dashboard__profile">
          <Avatar
            size={isCollapsed ? 40 : 55}
            src={
              'https://avatars.githubusercontent.com/u/44439185?s=460&u=471baa9e72545be97ae83b22a817e61c79d3be35&v=4'
            }
          />
          {!isCollapsed && <h3 className="text-white capital">{username}</h3>}
        </div>

        <Menu
          theme="dark"
          mode="inline"
          className="dashboard__sidebar-menu"
          onSelect={(value) => setCurrentPage(value.key)}
          defaultSelectedKeys={`/${window.location.pathname.split('/')[1]}`}
        >
          <Menu.Item
            className="dashboard__sidebar-menu--item"
            key="dashboard"
            icon={<DashboardIcon />}
          >
            <NavLink to="/">Dashboard</NavLink>
          </Menu.Item>
          <Menu.Item
            className="dashboard__sidebar-menu--item"
            key="my-farm"
            icon={<FarmIcon />}
          >
            <NavLink to="/my-farm">GREENS IoT</NavLink>
          </Menu.Item>
          <Menu.Item
            className="dashboard__sidebar-menu--item"
            key="my-product"
            icon={<ProductIcon />}
          >
            <NavLink to="/my-product">GREENS Products</NavLink>
          </Menu.Item>
          <Menu.Item
            className="dashboard__sidebar-menu--item"
            key="greenspod-map"
            icon={<MapIcon />}
          >
            <NavLink to="/greenspod-map">GREENS Pod Map</NavLink>
          </Menu.Item>
          {/* <Menu.Item
            className="dashboard__sidebar-menu--item"
            key="5"
            icon={<FarmIcon />}
          >
            Schedule
          </Menu.Item>
          <Menu.Item
            className="dashboard__sidebar-menu--item"
            key="6"
            icon={<FarmIcon />}
          >
            Analytics
          </Menu.Item> */}
        </Menu>

        <Menu
          theme="dark"
          mode="inline"
          className="dashboard__sidebar-menu-bottom"
          onSelect={(value) => setCurrentPage(value.key)}
          defaultSelectedKeys={`/${window.location.pathname.split('/')[1]}`}
        >
          {/* <Menu.Item
            className="dashboard__sidebar-menu--item"
            key="settings"
            icon={<SettingsIcon />}
          >
            Settings
          </Menu.Item> */}
          <Menu.Divider />
          <Menu.Item
            className="dashboard__sidebar-menu--item"
            key="logout"
            icon={<LogoutIcon />}
            onClick={() => {
              localStorage.removeItem('secret');
              window.location.replace('/');
            }}
          >
            Logout
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout>
        <Header className="dashboard__header">
          <Row align="middle" justify="center" className="u-mt-1">
            <Col>
              <img src={Logo} className="dashboard__header--logo" />
            </Col>
          </Row>
          <Row className="clear-ln-height">
            <p className="text-grey clear-ln-height">
              Welcome back, <span className="capital">{username}</span>
            </p>
          </Row>
        </Header>
        <Content
          className={
            currentPage === 'greenspod-map' ? '' : 'dashboard__content'
          }
        >
          <Switch>
            <Route exact path="/" component={HomePage} />
            <Route exact path="/my-farm" component={MyFarmPage} />
            <Route exact path="/my-product" component={MyProductPage} />
            <Route exact path="/greenspod-map" component={GreenspodMapPage} />
          </Switch>
        </Content>
        <Footer className="footer">
          <h1>Copyright 2021, Greens. All right reserved.</h1>
        </Footer>
      </Layout>
    </Layout>
  );
}
